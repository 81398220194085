const SettingsMenu = [
  {
    heading: "Audit Rail View",
    route: "/audit-rail",
    pages: [
      {
        heading: "View Audit Rail",
        route: "/audit-rail/search",
        fontIcon: "fas fa-comment-dots",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
     
    ],
  },
];

export default SettingsMenu;
