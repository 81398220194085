
import { defineComponent } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KTToggleLink from "@/layout/header/partials/ToggleMenu.vue";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    KTToggleLink,
  },
  data() {
    return {
      employee: {
        id: "",
        name: "",
        designation: "",
        nid: "",
        passport: "",
        driving_license: "",
        dob: "",
        email: "",
        mobile: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",

        religion: "",
        gender: "",
      },
      api_url: "",
      employee_image: "" as any,
      employee_name: "" as any,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      list: [],
      entity_short_name: "",
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.userInfo = VueCookieNext.getCookie("_seip_user");
    await this.getUserDesignation();
    this.emitter.on("employee_update_done", async (data) => {
      await this.getEmployeeProfile();
    });
    await this.getEmployeeProfile();
  },
  methods: {
    async getEmployeeProfile() {
      await ApiService.get(
        "configurations/employee/show/" + this.userInfo.employee.id
      )
        .then((response) => {
          this.employee_image = response.data.data.attach_file_info?.file_path;
          this.employee_name = response.data.data.name;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getUserDesignation() {
      let entity_id = "";
      let institute_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_institute_info_id");
        await ApiService.get("institute/show/" + institute_id)
          .then((response) => {
            this.entity_short_name = response.data.data.short_name;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        entity_id = VueCookieNext.getCookie("_entity_id");
        await ApiService.get("entity/show/" + entity_id)
          .then((response) => {
            this.entity_short_name = response.data.data.entity_short_name;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
  },
});
