
import { defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTTopbarButton from "@/layout/header/TopBarButton.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { VueCookieNext } from "vue-cookie-next";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
    KTTopbarButton,
  },
   data() {
    return {
      show: false,
    }
   },

  async created() {
    let today = new Date().toISOString().slice(0, 10)
    if (today <= '2023-03-09'){
      if (VueCookieNext.getCookie("women_popup") == 1) {
        this.show = false;
      }else{
         this.show = true;
      }
      
    }
    setTimeout(() => (this.show = false), 10000);
  
  },
  methods: {
    async hide() {
      this.show = false;
       VueCookieNext.setCookie("women_popup", '1');
    },
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
