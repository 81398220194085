
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-tranche-institute",
  components: { Field },

  data() {
    return {
      oldauditLog: [] as any,
      newauditLog: [] as any,
      id: "",
      parent_tranche_id: "",
      tranche_no: "",
      label: "",
      short_name: "",
      start_date: "",
      end_date: "",
      donor_agencies: "",
      amount: "",
      tranches: [],
      load: false,
      loading: false,
    };
  },
  async created() {
    this.emitter.on("audit-rail-view", async (data) => {
      this.oldauditLog = JSON.parse(data.old_data);
      this.newauditLog = JSON.parse(data.new_data);

      console.log(this.oldauditLog);
      console.log(this.newauditLog);
    });
  },
  methods: {
    async getTranches() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let data = {
        id: this.id,
        parent_tranche_id: this.parent_tranche_id,
        tranche_no: this.tranche_no,
        label: this.label,
        short_name: this.short_name,
        start_date: this.start_date,
        end_date: this.end_date,
        donor_agencies: this.donor_agencies,
        amount: this.amount,
      };

      this.loading = true;
      await ApiService.post("configurations/tranche/save", data)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit("tranche-added", true);
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.parent_tranche_id = "";
              this.tranche_no = "";
              this.label = "";
              this.short_name = "";
              this.start_date = "";
              this.end_date = "";
              this.donor_agencies = "";
              this.amount = "";
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
